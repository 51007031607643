import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { ChildImageSharp } from "../types";
import Img from "gatsby-image";

type PageProps = {
  logoImage: ChildImageSharp;
};

const Logo = () => {
  const props: PageProps = useStaticQuery(query);
  return (
    <Img
      fluid={props.logoImage.childImageSharp.fluid}
      imgStyle={{ borderRadius: "50%" }}
    />
  );
};

export default Logo;

export const query = graphql`
  query LogoImageQuery {
    logoImage: file(
      sourceInstanceName: { eq: "images" }
      name: { eq: "favicon" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 96) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
